import { easepick, LockPlugin, RangePlugin } from "@easepick/bundle"
import { atBreakpoint, interactiveText, menuArrow } from "#js/components/styles"
import { capFirst, getByCount } from "#js/components/utils"
import { createRef, ref } from "lit/directives/ref.js"
import { css, html } from "lit"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { UrlBasedComponent } from "#js/components/urlBasedComponent"
import { errorReload } from "#js/components/http"
import { gettext } from "#js/components/i18n"
import { initGeocoder } from "#js/integrations/geocoder"
import { setLocale } from "#js/components/lit-i18n"
import { styleMap } from "lit/directives/style-map.js"

/**
 * Base class for filters
 *
 * Handle the communication with the search result component.
 * On load, it will try to add itself to the search component,
 * if it's not loaded yet, it will listen for the search-ready event.
 * @property {string} for - The id of the search component
 * @augments UrlBasedComponent
 */
export class FilterBase extends UrlBasedComponent {
  static properties = {
    for: { type: String },
  }

  search = null

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  firstUpdated() {
    this.search = document.getElementById(this.for)
    this.onComponentChanged()
  }

  /**
   * Notify the search component that the filter has changed
   */
  onComponentChanged() {
    this.search.onFilterChanged(this)
  }

  /**
   * Called by the search component when the filter has changed.
   * Implement this method to update the filter state (helper).
   * @param {object} helper - the search query builder (algoliasearchHelper)
   */
  applyFilter(helper) {
    throw new Error("applyFilter must be implemented")
  }

  getResetButton() {
    if (!this.isDefaultState()) {
      return html`
        <button class="filter__reset" @click="${this.resetState}">
          ${msg("reset")}
        </button>`
    }
  }
}

export class ActivityTypeFilter extends FilterBase {
  static properties = {
    activityTypes: { type: Object },
  }

  defaultState = {
    activity_type: {
      value: [],
      type: "Array",
    },
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <fieldset class="filter">
        <div class="filter__header">
          <legend class="filter__legend">
            ${capFirst(msg("formats"))}
          </legend>
          ${this.getCounter()}
          ${this.getResetButton()}
        </div>
        <div class="multiselect material">
          ${[...this.getActivityTypes()]}
        </div>
      </fieldset>
    `
  }

  getCounter() {
    if (this.state.activity_type.value.length > 0) {
      return html`
        <div class="filter__counter">
          ${this.state.activity_type.value.length}
        </div>
      `
    }
  }

  getActivityTypes() {
    return Object.entries(this.activityTypes || {}).map(([key, value]) => {
      return html`
        <p class="multiselect__item">
          <input id="activity-type-${key}"
                 value="${key}"
                 name="activity-types"
                 type="checkbox"
                 style="margin-inline-start: 0"
                 .checked="${this.state.activity_type.value.includes(key)}"
                 @change="${this.changed}"/>
          <label for="activity-type-${key}"
                 class="multiselect__label">
            ${value}
          </label>
        </p>
      `
    })
  }

  changed(event) {
    if (event.target.checked) {
      this.state.activity_type.value.push(event.target.value)
    } else {
      this.state.activity_type.value = this.state.activity_type.value.filter((value) =>
        value !== event.target.value
      )
    }
    this.onStateChange()
  }

  applyFilter(helper) {
    helper.removeDisjunctiveFacetRefinement("activity_type")
    if (!this.isDefaultState()) {
      this.state.activity_type.value.forEach((value) => {
        helper.addDisjunctiveFacetRefinement("activity_type", value)
      })
    }
  }
}

globalThis.customElements.define("activity-type-filter-lit", ActivityTypeFilter)

export class AgeFilter extends FilterBase {
  static properties = {
    minAge: { type: String },
    maxAge: { type: String },
  }

  defaultState = {
    age: {
      value: "",
      type: "String",
    },
  }

  constructor() {
    super()
    this.minAgeLimit = 1
    this.maxAgeLimit = 18
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <fieldset class="filter">
        <div class="filter__header">
          <legend class="filter__legend">
            ${capFirst(msg("child's age"))}${this.getAgeLabel()}
          </legend>
          ${this.getResetButton()}
        </div>
        <div class="material">
          <p>
            <select id="age-select"
                    @change="${this.handleAgeChange}">
              <option value=''>${msg("every")}</option>
              ${this.getAges()}
            </select>
            <label for="age-select">${capFirst(msg("child's age"))}</label>
          </p>
        </div>
      </fieldset>
    `
  }

  generateAgeRange() {
    const from = this.minAge ? parseInt(this.minAge) : this.minAgeLimit
    const to = this.maxAge ? parseInt(this.maxAge) : this.maxAgeLimit

    return Array.from({ length: to + 1 - from }, (_, i) => i + from)
  }

  getAges() {
    return html`
      ${
      this.generateAgeRange().map((age) => {
        return html`
          <option value='${age}' .selected="${this.state.age.value === `${age}`}">
            ${age}&nbsp;${getByCount(age, msg("year"), msg("years"))}
          </option>
        `
      })
    }
    `
  }

  handleAgeChange(event) {
    this.state.age.value = event.target.value
    this.onStateChange()
  }

  getAgeLabel() {
    if (!this.isDefaultState()) {
      return html`:&nbsp;${this.state.age.value}&nbsp;${msg("years")}`
    }
  }

  applyFilter(helper) {
    helper.removeNumericRefinement("min_age")
    helper.removeNumericRefinement("max_age")
    if (!this.isDefaultState()) {
      helper.addNumericRefinement("min_age", "<=", this.state.age.value)
      helper.addNumericRefinement("max_age", ">=", this.state.age.value)
    }
  }
}

globalThis.customElements.define("age-filter-lit", AgeFilter)

export class DateFilter extends FilterBase {
  static properties = {
    stylesPath: { type: String },
  }

  defaultState = {
    start: {
      value: null,
      type: "Number",
    },
    end: {
      value: null,
      type: "Number",
    },
  }

  datepickerRef = createRef()
  picker = null

  firstUpdated() {
    super.firstUpdated()
    this.picker = this.createEasePicker()
    if (this.state.start.value) {
      this.picker.gotoDate(this.getStartDate())
    }
    this.picker.on("select", this.onEasePickerChange.bind(this))
  }

  createEasePicker() {
    // eslint-disable-next-line new-cap
    return new easepick.create({
      element: this.datepickerRef.value,
      lang: globalThis.language,
      inline: true,
      css: [`${this.stylesPath}`],
      zIndex: 10,
      plugins: [
        RangePlugin,
        LockPlugin,
      ],
      RangePlugin: {
        startDate: this.getStartDate(),
        endDate: this.getEndDate(),
      },
      LockPlugin: {
        minDate: new Date(),
      },
    })
  }

  // 24hrs (minus 1ms) is added to this.state.end.value to include the last day of
  // the selected date range in the search,
  // while still displaying the correct date in the legend
  onEasePickerChange(e) {
    const { start, end } = e.detail
    this.state.start.value = Math.floor(start.getTime() / 1000)
    this.state.end.value = Math.floor((end.getTime() / 1000) + 24 * 60 * 60 - 1)
    this.onStateChange()
    this.requestUpdate()
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <fieldset class="filter">
        <div class="filter__header">
          <legend class="filter__legend">
            ${capFirst(msg(str`time period`))}${this.getDateDisplay()}
          </legend>
          ${this.getResetButton()}
        </div>
        <div class="date-filter">
          <input ${ref(this.datepickerRef)} style="display: none;">
        </div>
      </fieldset>
    `
  }

  resetState() {
    super.resetState()
    this.picker.clear()
  }

  getStartDate() {
    if (this.state.start.value) {
      return new Date(this.state.start.value * 1000)
    }
  }

  getEndDate() {
    if (this.state.end.value) {
      return new Date(this.state.end.value * 1000)
    }
  }

  getDateDisplay() {
    if (this.state.start.value && this.state.end.value) {
      const startDate = this.getStartDate().toLocaleDateString(globalThis.language)
      const endDate = this.getEndDate().toLocaleDateString(globalThis.language)

      if (startDate === endDate) {
        return html`:
        <p>${startDate}</p>
        `
      }
      return html`:
      <p>
        ${startDate}
        ${msg(str`until`)}
        ${endDate}
      </p>
      `
    }
  }

  applyFilter(helper) {
    helper.removeNumericRefinement("start_date")
    helper.removeNumericRefinement("end_date")
    if (!this.isDefaultState()) {
      helper.addNumericRefinement("start_date", ">=", this.state.start.value)
      helper.addNumericRefinement("end_date", "<=", this.state.end.value)
    }
  }
}

globalThis.customElements.define("date-filter-lit", DateFilter)

export class LocationFilter extends FilterBase {
  static properties = {
    defaultPosition: { type: String },
    defaultCoordinates: { type: String },
  }

  createRenderRoot() {
    return this
  }

  defaultState = {
    is_virtual: {
      value: null,
      type: "Boolean",
    },
    distance: {
      value: 100,
      type: "Number",
    },
    positionLabel: {
      value: "",
      type: "String",
    },
    coordinates: {
      value: "",
      type: "String",
    },
  }

  geocoderRef = createRef()

  connectedCallback() {
    this.defaultState.positionLabel.value = this.defaultPosition
    this.defaultState.coordinates.value = this.defaultCoordinates
    super.connectedCallback()
  }

  firstUpdated() {
    super.firstUpdated()
    this.geocoder = this.createGeoCoder()

    this.geocoder.on("result", this.onGeoCoderResult.bind(this))
  }

  createGeoCoder() {
    return initGeocoder(
      this.geocoderRef.value,
      this.state.positionLabel.value,
      null,
      "position-filter-label-id",
      "position-filter-input",
    )
  }

  onGeoCoderResult(results) {
    // mapbox provides coordinates in the format [longitude, latitude]
    // https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
    const coords = results.result.geometry.coordinates
    // algolia expects coordinates in the format [latitude, longitude]
    // https://www.algolia.com/doc/guides/managing-results/refine-results/geolocation/how-to/filter-results-around-a-location/#filtering-around-a-given-location
    this.state.coordinates.value = `${coords[1].toFixed(2)},${coords[0].toFixed(2)}`
    this.state.positionLabel.value = results.result.place_name.split(",").slice(0, -1)
      .join()
    this.onStateChange()
  }

  render() {
    return html`
      <fieldset class="filter" style="overflow: visible">
        <div class="filter__header">
          <legend class="filter__legend">
            ${capFirst(msg(str`location`))}
          </legend>
          ${this.getResetButton()}
        </div>
        <div class="material layout" style="gap:var(--space--quarter)">
          ${this.getVirtual()}
          <div class="layout"
               style="${this.state.is_virtual.value === false ? "" : "display: none"};
               gap:1em">
            ${this.getGeocoder()}
            ${this.getDistance()}
          </div>
        </div>
      </fieldset>
    `
  }

  getVirtual() {
    return html`
      <div>
        ${
      [
        [capFirst(msg(str`all offers`)), null, "all"],
        [capFirst(msg(str`online offers`)), true, "online"],
        [capFirst(msg(str`offline offers`)), false, "offline"],
      ].map(
        ([label, value, id]) =>
          html`
            <p>
              <input type="radio"
                     id="virtual-filter-${id}"
                     value="${label}"
                     name="is_virtual"
                     @change="${() => {
            this.state.is_virtual.value = value
            this.onStateChange()
          }}"
                     .checked="${this.state.is_virtual.value === value}"/>
              <label for="virtual-filter-${id}">
                ${label}
              </label>
            </p>
          `,
      )
    }
      </div>
    `
  }

  getGeocoder() {
    return html`
      <div ${ref(this.geocoderRef)}
           data-for-label-id="position-filter-input"
           data-label-id="position-filter-label-id">
        <label id="position-filter-label-id"
               for="position-filter-input"
               class="floating-label-always-on-top">
          ${capFirst(msg(str`position`))}
        </label>
      </div>
    `
  }

  getDistance() {
    return html`
      <div class="row">
        <label for="distance-filter">
          ${capFirst(msg(str`distance`))}
        </label>
        <span style="margin-inline-start: auto">
          ${
      this.state.distance.value === 100
        ? msg(str`any`)
        : html`${msg("until")}&nbsp;${this.state.distance.value}&nbsp;km`
    }
        </span>
      </div>
      <input id="distance-filter"
             .value="${this.state.distance.value}"
             @input="${(event) => {
      this.state.distance.value = parseInt(event.target.value)
      this.onStateChange()
    }}"
             type="range"
             min="1"
             max="100">
    `
  }

  resetState() {
    super.resetState()
    this.geocoder.setInput(this.state.positionLabel.value)
  }

  applyFilter(helper) {
    helper.removeFacetRefinement("is_virtual")
    helper.setQueryParameter("aroundLatLng")
    helper.setQueryParameter("aroundRadius")
    if (!this.isDefaultState()) {
      if (this.state.is_virtual.value !== null) {
        helper.addFacetRefinement("is_virtual", this.state.is_virtual.value)
      }
      if (this.state.is_virtual.value === false) {
        helper.setQueryParameter("aroundLatLng", this.state.coordinates.value)
        if (this.state.distance.value && this.state.distance.value !== 100) {
          helper.setQueryParameter("aroundRadius", this.state.distance.value * 1000)
        }
      }
    }
  }
}

globalThis.customElements.define("location-filter-lit", LocationFilter)

export class PaginationFilter extends FilterBase {
  static properties = {
    totalPages: { type: Number },
  }

  defaultState = {
    page: {
      value: 0,
      type: "Number",
    },
  }

  render() {
    if (this.totalPages > 1) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}">
        <div class="pagination">
          ${this.getPreviousButton()}
          ${this.getPages()}
          ${this.getNextButton()}
        </div>
      `
    }
  }

  getPreviousButton() {
    const styles = {
      visibility: this.state.page.value > 0 ? "visible" : "hidden",
      "flex-shrink": 0,
    }

    return html`
      <ui-button @click="${this.previous}" style="${styleMap(styles)}">
        <svg class="icon icon--large">
          <use href="${globalThis.svgSprite}#AngleBoldLeft"></use>
        </svg>
      </ui-button>
    `
  }

  /**
   * Create an array containing the page numbers to display.
   * The array will contain the first, last, and current page numbers,
   * as well as the pages within the boundary.
   * @param {number} current - the current active page
   * @param {number} total - the total number of pages
   * @param {number} boundary - the number of pages to display on either side of the current page
   * @returns {number[]} - an array of page numbers
   */
  getPagination(current, total, boundary) {
    const lowerBound = Math.max(1, current - boundary)
    const upperBound = Math.min(total, current + boundary)

    // Adds the first and last pages to the page array.
    const pages = new Set([1, total])

    // Adds the current page and its adjacent pages to the page array.
    for (let i = lowerBound; i <= upperBound; i++) pages.add(i)

    // If the current page is the first page, the paginator should show the first 3 pages.
    if (current === 1) {
      for (let i = 1; i <= Math.min(3, total); i++) pages.add(i)
    }
    // If the current page is the last page, the paginator should show the last 3 pages.
    if (current === total) {
      for (let i = Math.max(1, total - 2); i <= total; i++) pages.add(i)
    }

    return Array.from(pages).sort((a, b) => a - b)
  }

  /**
   * Add an ellipsis to an array of numbers if there is a gap of at least 1 between the pages.
   * @param {number[]} numbers - Sorted page numbers.
   * @yields {number|string} - Either a number from the input array or an ellipsis.
   */
  *addEllipsis(numbers) {
    for (let i = 0; i < numbers.length; i++) {
      yield numbers[i]
      if (numbers[i + 1] - numbers[i] > 1) {
        yield "ellipsis"
      }
    }
  }

  getPages() {
    return html`
      ${
      [...this.addEllipsis(
        this.getPagination(this.state.page.value + 1, this.totalPages, 1),
      )].map((item) => {
        switch (item) {
          case "ellipsis":
            return html`<div>&hellip;</div>`
          case this.state.page.value + 1:
            return html`<div class="row row--centered pagination__button-active">${item}</div>`
          default:
            return html`
              <ui-button class="pagination-button"
                         @click='${() => this.goToPage(item)}'>
                ${item}
              </ui-button>
            `
        }
      })
    }
    `
  }

  getNextButton() {
    const styles = {
      visibility: this.state.page.value < this.totalPages - 1 ? "visible" : "hidden",
      "flex-shrink": 0,
    }

    return html`
      <ui-button @click="${this.next}" style="${styleMap(styles)}">
        <svg class="icon icon--large">
          <use href="${globalThis.svgSprite}#AngleBoldRight"></use>
        </svg>
      </ui-button>
    `
  }

  previous() {
    this.state.page.value = this.state.page.value - 1
    globalThis.scrollTo(0, 0)
    this.onStateChange()
  }

  next() {
    this.state.page.value = this.state.page.value + 1
    globalThis.scrollTo(0, 0)
    this.onStateChange()
  }

  goToPage(page) {
    this.state.page.value = page - 1
    globalThis.scrollTo(0, 0)
    this.onStateChange()
  }

  setPage(currentPage, totalPages) {
    if (this.state.page) {
      this.state.page.value = currentPage
      this.totalPages = totalPages
      this.updateUrlParams()
      this.requestUpdate()
    } else {
      errorReload(
        gettext(
          "Ups, there seems to be problem.<br>Please reach out to us, if this continues to happen.",
        ),
      )
    }
  }

  applyFilter(helper) {
    helper.setPage(0)
    if (!this.isDefaultState()) {
      helper.setPage(this.state.page.value)
    }
  }
}

globalThis.customElements.define("pagination-filter", PaginationFilter)

export class DayFilter extends FilterBase {
  static properties = {
    activityTypes: { type: Object },
    targetGroupIds: { type: Array },
  }

  defaultState = {
    date: {
      value: new Date(),
      type: "Date",
    },
    activity_type: {
      value: [],
      type: "Array",
    },
  }

  static styles = css`
    :host {
      font-size: 2.5rem;
      color: var(--black-67);
      text-align: center;

      ${
    atBreakpoint(
      "mobile",
      css`
        font-size: 2rem;
      `,
    )
  }
    }

    .interactive {
      display: inline-block;
      font-weight: bold;
      position: relative;
      line-height: 3rem;
      ${interactiveText}
    }

    select, input[type="date"]::-webkit-calendar-picker-indicator {
      appearance: none;
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }

    select, input[type="date"] {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 250%;
      font-size: 2rem;
      top: -0.5em;
      z-index: 1;
    }

    .arrow {
      ${menuArrow}
    }
  `

  connectedCallback() {
    super.connectedCallback()
    if (this.targetGroupIds) {
      this.activityTypes = { "for-you": gettext("for you"), ...this.activityTypes }
      this.defaultState.activity_type.value = ["for-you"]
      this.state.activity_type.value = ["for-you"]
    } else {
      this.targetGroupIds = []
    }
  }

  render() {
    return msg(html`${this.renderFormat()} starting ${this.renderDate()}`)
  }

  renderFormat() {
    return html`
      <div class="interactive">
        <select @change="${this.setActivityType.bind(this)}">
          <option value="" .selected="${this.state.activity_type.value.length}">
            ${capFirst(msg("everything"))}
          </option>
          ${
      Object.entries(this.activityTypes).map(([key, value]) => {
        return html`
              <option value="${key}" .selected="${
          this.state.activity_type.value.includes(key)
        }">
                ${capFirst(value)}
              </option>
            `
      })
    }
        </select>
        <span style="margin-inline-end: -0.3em;">
          ${
      capFirst(
        this.state.activity_type.value.length
          ? this.activityTypes[this.state.activity_type.value]
          : msg("everything"),
      )
    }
        </span>
        <span class="arrow"></span>
      </div>
    `
  }

  renderDate() {
    return html`
      <div class="interactive">
        <input type="date" @change="${this.setDate.bind(this)}" min="${
      new Date().toISOString().slice(0, 10)
    }">
        ${
      (new Date(this.state.date.value)).toLocaleDateString(globalThis.language, {
        weekday: "long",
        month: "long",
        day: "numeric",
      })
    }
      </div>
    `
  }

  setDate(event) {
    this.state.date.value = event.target.value.length
      ? new Date(event.target.value)
      : new Date(new Date().toISOString().slice(0, 10))
    this.onStateChange()
  }

  setActivityType(event) {
    if (event.target.value === "") {
      this.state.activity_type.value = []
    } else {
      this.state.activity_type.value = [event.target.value]
    }
    this.onStateChange()
  }

  applyFilter(helper) {
    helper.removeNumericRefinement("start_date")
    helper.removeDisjunctiveFacetRefinement("activity_type")
    helper.removeDisjunctiveFacetRefinement("target_group_ids")
    if (this.state.activity_type.value.includes("for-you")) {
      this.targetGroupIds.forEach((id) => {
        helper.addDisjunctiveFacetRefinement("target_group_ids", id)
      })
    } else {
      this.state.activity_type.value.forEach((value) => {
        helper.addDisjunctiveFacetRefinement("activity_type", value)
      })
    }

    if (this.state.date.value.getTime() !== this.defaultState.date.value.getTime()) {
      helper.addNumericRefinement(
        "start_date",
        ">=",
        this.state.date.value.getTime() / 1000,
      )
    }
  }
}

globalThis.customElements.define("day-filter", DayFilter)

export class ContinuousPagination extends FilterBase {
  static properties = {
    totalPages: { type: Number, attribute: false },
  }

  defaultState = {
    page: {
      value: 0,
      type: "Number",
    },
  }

  render() {
    if (this.totalPages > 1 && this.state.page.value < this.totalPages - 1) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}">
        <div class="button__group button__group--center">
          <ui-button class="elevated" style="margin: auto;" @click="${this.extend}">
            ${msg("show more")}
          </ui-button>
        </div>
      `
    }
  }

  extend() {
    this.state.page.value += 1
    this.onStateChange()
  }

  setPage(currentPage, totalPages) {
    this.state.page.value = currentPage
    this.totalPages = totalPages
    this.updateUrlParams()
  }

  applyFilter(helper) {
    helper.setPage(this.defaultState.page.value)
    if (!this.isDefaultState()) {
      helper.setPage(this.state.page.value)
    }
  }
}

globalThis.customElements.define("continuous-pagination", ContinuousPagination)

export class LanguageFilter extends FilterBase {
  defaultState = {
    languages: {
      value: [],
      type: "Array",
    },
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <fieldset class="filter">
        <div class="filter__header">
          <legend class="filter__legend">
            ${capFirst(msg("languages"))}
          </legend>
          ${this.getResetButton()}
        </div>
        <div class="material">
          <p>
            <select id="language-select"
                    @change="${this.handleLanguageChange}">
              <option value=''>${capFirst(msg("all"))}</option>
              ${this.getLanguages()}
          </p>
        </div>
      </fieldset>
    `
  }

  getLanguages() {
    return html`
      ${
      [
        ["DE", "Deutsch"],
        ["EN", "English"],
      ].map(([key, value]) => {
        return html`
          <option value='${key}' .selected="${
          this.state.languages.value.includes(key)
        }">
            ${value}
          </option>
        `
      })
    }
    `
  }

  handleLanguageChange(event) {
    this.state.languages.value = event.target.value ? [event.target.value] : []
    this.onStateChange()
  }

  applyFilter(helper) {
    helper.removeDisjunctiveFacetRefinement("languages")
    if (!this.isDefaultState()) {
      this.state.languages.value.forEach((value) => {
        helper.addDisjunctiveFacetRefinement("languages", value)
      })
    }
  }
}

globalThis.customElements.define("language-filter", LanguageFilter)
